@import url('https://fonts.googleapis.com/css?family=Orbitron');

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6441A5;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #23272a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
@media (max-width: 768px) {
  .fadeshow {
    display: none;
    visibility: hidden;
  }
}

.testfont{
  font-family: 'Orbitron', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
html body {
  background-color: #23272a;
}
.card-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.bg-twitch {
  background-color: #23272a;
}
.flex-container {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.text-gold {
  color: var(--warning);
  
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-truncate {
  -webkit-text-fill-color: #6610f2;
}
.imagesize {
  max-width: 8em;
  max-height: 8em;
  margin: auto;
}
.footer {
  background-color: #e9ecef;
  height: 4em;
}
.centerfooter {
  margin:auto;
}
